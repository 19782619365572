import { useNavigate } from 'react-router-dom';

import { AuthLayout } from '@/components/layouts/auth-layout';
import { Button } from '@/ui/button';

export const PendingRoute = () => {
  const navigate = useNavigate();
  return (
    <AuthLayout title="Account Pending Approval">
      <div className="flex flex-col items-center justify-center gap-4">
        <h1 className="text-2xl font-bold">Account Pending Approval</h1>
        <p className="text-center text-sm text-gray-500">
          Your account is still pending approval. You&apos;ll receive an email
          once your account is approved. If you have any questions, please reach
          out to our support team.
        </p>
        <Button onClick={() => navigate('/auth/login')}>Go to Login</Button>
      </div>
    </AuthLayout>
  );
};
