import type { AxiosError } from 'axios';
import { collection, doc, getDoc } from 'firebase/firestore';
import { createQuery } from 'react-query-kit';

import { db } from '@/firebase-config';

import { InfluencerPost } from '../types';

type Response = InfluencerPost;
type Variables = { id: string };

export const usePost = createQuery<Response, Variables, AxiosError>({
  queryKey: ['post'],
  fetcher: async ({ id }) => {
    try {
      const collectionRef = collection(db, 'influencerPosts');
      const docRef = doc(collectionRef, id);
      const docSnap = await getDoc(docRef);
      return docSnap.data() as InfluencerPost;
    } catch (error) {
      throw new Error('Post not found');
    }
  },
});
