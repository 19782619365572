import { signInWithCustomToken } from 'firebase/auth';
import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useUser } from '@/api/common/auth';
import { auth } from '@/firebase-config';
import { Spinner } from '@/ui/spinner';

export const SignAsRoute = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { data: user } = useUser();

  useEffect(() => {
    const token = searchParams.get('token');

    if (token) {
      signInWithCustomToken(auth, token)
        .then(() => {
          if (user) {
            navigate('/app', { replace: true });
          }
        })
        .catch(() => {
          navigate('/auth/login', { replace: true });
        });
    } else {
      navigate('/auth/login', { replace: true });
    }
  }, [searchParams, navigate, user]);

  return <Spinner />;
};
