import type { AxiosError } from 'axios';
import { createMutation } from 'react-query-kit';
import { z } from 'zod';

import { client } from '../common';
import { InfluencerAccount } from '../types';

type Variables = {
  accounts: {
    accountUrl: string;
  }[];
};
type Response = InfluencerAccount[];

export const useSubmitAccounts = createMutation<
  Response,
  Variables,
  AxiosError
>({
  mutationFn: async (variables) =>
    client({
      url: '/influencers/accounts',
      method: 'POST',
      data: variables,
    }).then((response) => response.data),
});

export const submitAccountInputSchema = z.object({
  accountUrl: z.string().url('Invalid URL'),
});
