import * as Sentry from '@sentry/react';
import posthog from 'posthog-js';
import * as React from 'react';
import { createRoot } from 'react-dom/client';

import './index.css';
import { App } from './app';
import { env } from './config/env';

Sentry.init({
  dsn: 'https://9ff201b60b80f61c2c4d75545cd5e2f6@o4507010009989120.ingest.us.sentry.io/4508054361210880',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ['localhost', env.API_URL],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  enabled: env.APP_ENV !== 'development',
});

posthog.init('phc_wZqShxHC9RQscx1h5l9pvoNLvX62sueAUvFHJdsIi21', {
  api_host: 'https://us.i.posthog.com',
  person_profiles: 'identified_only',
});

const root = document.getElementById('root');
if (!root) throw new Error('No root element found');

createRoot(root).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
