import console from 'console';

import type { AxiosError } from 'axios';
import { getAuth } from 'firebase/auth';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { createQuery } from 'react-query-kit';

import { db } from '@/firebase-config';

import { InfluencerPostAnalysis } from '../types';

type Response = InfluencerPostAnalysis[];
type Variables = void;

export const usePostAnalyses = createQuery<Response, Variables, AxiosError>({
  queryKey: ['post-analyses'],
  fetcher: async () => {
    try {
      const auth = getAuth();
      const uid = auth.currentUser?.uid;
      const collectionRef = collection(db, 'influencerPostAnalyses');
      const q = query(collectionRef, where('uid', '==', uid));
      const querySnapshot = await getDocs(q);
      return querySnapshot.docs.map((doc) =>
        doc.data(),
      ) as InfluencerPostAnalysis[];
    } catch (error) {
      console.log(error);
      throw new Error('Posts not found');
    }
  },
});
