import type { AxiosError } from 'axios';
import { getAuth, updateEmail, User } from 'firebase/auth';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { createMutation } from 'react-query-kit';
import { z } from 'zod';

import { db } from '@/firebase-config';

type Variables = {
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  country: string;
};
type Response = void;

export const useUpdateProfile = createMutation<Response, Variables, AxiosError>(
  {
    mutationFn: async (variables) => {
      const auth = getAuth();
      const uid = auth.currentUser?.uid as string;
      const docRef = doc(db, 'influencers', uid);
      const influencer = (await getDoc(docRef)).data();
      if (influencer?.email !== variables.email && variables.email) {
        await updateEmail(auth.currentUser as User, variables.email as string);
      }
      await updateDoc(docRef, variables);
      return;
    },
  },
);

export const updateProfileInputSchema = z.object({
  country: z.string().min(1, 'Required'),
  email: z.string().min(1, 'Required').email('Invalid email'),
  firstName: z.string().min(1, 'Required'),
  lastName: z.string().min(1, 'Required'),
  phoneNumber: z.string().min(1, 'Required'),
});
