import * as React from 'react';

import { Head } from '@/components/head';

type ContentLayoutProps = {
  children: React.ReactNode;
  title: string;
};

export const ContentLayout = ({ children, title }: ContentLayoutProps) => {
  return (
    <>
      <Head title={title} />
      <div className="sm:py-6">
        <div className="mx-auto max-w-7xl px-4 sm:p-6 md:px-8">{children}</div>
      </div>
    </>
  );
};
