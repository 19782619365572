import { AxiosError } from 'axios';
import {
  createUserWithEmailAndPassword,
  getAuth,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
} from 'firebase/auth';
import { getDoc, doc, setDoc } from 'firebase/firestore';
import posthog from 'posthog-js';
import { configureAuth } from 'react-query-auth';
import { createMutation } from 'react-query-kit';
import { Navigate, useLocation } from 'react-router-dom';
import { z } from 'zod';

import { generateSpecialCode, Influencer, useAccounts } from '@/api';
import { auth, db } from '@/firebase-config';
import { useNotifications } from '@/ui/notifications';
declare global {
  interface Window {
    Atlas: any;
  }
}
const getUser = async (): Promise<Influencer> => {
  const auth = getAuth();
  const user = auth.currentUser;
  if (user) {
    const uid = user.uid;
    if (!uid) throw new Error('User ID not provided');
    const docSnap = await getDoc(doc(db, 'influencers', uid));
    if (!docSnap.exists()) throw new Error('User not found');
    return docSnap.data() as Influencer;
  } else {
    throw new Error('User not found');
  }
};

const logout = async (): Promise<void> => {
  return signOut(auth);
};

export const loginInputSchema = z.object({
  email: z.string().min(1, 'Required').email('Invalid email'),
  password: z.string().min(5, 'Required'),
});

export type LoginInput = z.infer<typeof loginInputSchema>;
const loginWithEmailAndPassword = async (
  data: LoginInput,
): Promise<Influencer> => {
  const { email, password } = data;
  await signInWithEmailAndPassword(auth, email, password);
  const influencer = await getUser();
  window.Atlas.call('identify', {
    userId: influencer.uid,
    name: `${influencer.firstName} ${influencer.lastName}`,
    email: influencer.email,
    customFields: {
      type: 'Influencer',
    },
  });
  return influencer;
};

export const registerInputSchema = z
  .object({
    email: z.string().min(1, 'Required').email('Invalid email'),
    firstName: z.string().min(1, 'Required'),
    lastName: z.string().min(1, 'Required'),
    phoneNumber: z.string().min(1, 'Required'),
    password: z
      .string()
      .min(1, 'Required')
      .min(6, 'Password must be at least 5 characters'),
    confirmPassword: z
      .string()
      .min(1, 'Required')
      .min(6, 'Password must be at least 5 characters'),
    payoutMethod: z.enum(['wire', 'paypal']),
    payoutEmail: z.string().min(1, 'Required').email('Invalid email'),
    country: z.string().min(1, 'Required'),
  })
  .superRefine(({ confirmPassword, password }, ctx) => {
    if (confirmPassword !== password) {
      ctx.addIssue({
        code: 'custom',
        message: 'The passwords did not match',
        path: ['confirmPassword'],
      });
    }
  });

export type RegisterInput = z.infer<typeof registerInputSchema>;

const registerWithEmailAndPassword = async (
  data: RegisterInput,
): Promise<Influencer> => {
  const {
    email,
    password,
    firstName,
    lastName,
    phoneNumber,
    payoutMethod,
    payoutEmail,
    country,
  } = data;
  const authResponse = await createUserWithEmailAndPassword(
    auth,
    email,
    password,
  );
  const specialCode = 'special-' + generateSpecialCode();

  await setDoc(doc(db, 'specialReferralCodes', specialCode), {
    referralCode: specialCode,
    created: new Date().toISOString(),
    influencerName: `${firstName} ${lastName}`,
  });
  const influencer: Influencer = {
    firstName,
    lastName,
    phoneNumber,
    email,
    payoutMethod,
    payoutEmail,
    status: 'PENDING',
    uid: authResponse.user.uid,
    country,
    specialCode,
    hasCompletedOnboarding: false,
  };
  await setDoc(doc(db, 'influencers', authResponse.user.uid), influencer);
  window.Atlas.call('identify', {
    userId: influencer.uid,
    name: `${influencer.firstName} ${influencer.lastName}`,
    email: influencer.email,
    customFields: {
      type: 'Influencer',
    },
  });
  return influencer;
};

const authConfig = {
  userFn: getUser,
  loginFn: async (data: LoginInput) => {
    try {
      const influencer = await loginWithEmailAndPassword(data);
      posthog.identify(influencer.uid, {
        email: influencer.email,
        name: `${influencer.firstName} ${influencer.lastName}`,
      });
      return influencer;
    } catch (error: any) {
      const message = error.response?.data?.message || error.message;
      useNotifications.getState().addNotification({
        type: 'error',
        title: 'Error',
        message,
      });
      throw error;
    }
  },
  registerFn: async (data: RegisterInput) => {
    try {
      const influencer = await registerWithEmailAndPassword(data);
      posthog.identify(influencer.uid, {
        email: influencer.email,
        name: `${influencer.firstName} ${influencer.lastName}`,
      });
      return influencer;
    } catch (error: any) {
      const message = error.response?.data?.message || error.message;
      useNotifications.getState().addNotification({
        type: 'error',
        title: 'Error',
        message,
      });
      throw error;
    }
  },
  logoutFn: logout,
};

export const { useUser, useLogin, useLogout, useRegister, AuthLoader } =
  configureAuth(authConfig);

export const ProtectedRoute = ({
  children,
  requireOnboarding = true,
}: {
  children: React.ReactNode;
  requireOnboarding?: boolean;
}) => {
  const { data: user } = useUser();
  const { data: accounts } = useAccounts();
  const location = useLocation();
  if (!user) {
    return (
      <Navigate
        to={`/auth/login?redirectTo=${encodeURIComponent(location.pathname)}`}
        replace
      />
    );
  }
  if (accounts && accounts.length === 0 && user.status === 'PENDING') {
    return <Navigate to="/auth/account" replace />;
  }
  if (accounts && accounts.length > 0 && user?.status === 'PENDING') {
    return <Navigate to="/auth/pending" replace />;
  }

  if (requireOnboarding) {
    if (user.hasCompletedOnboarding === false) {
      return <Navigate to="/onboarding/welcome" replace />;
    }
  } else {
    if (user.hasCompletedOnboarding === true) {
      return <Navigate to="/app" replace />;
    }
  }

  return children;
};

export const useForgotPassword = createMutation<
  void,
  ForgotPasswordInput,
  AxiosError
>({
  mutationFn: async (data: ForgotPasswordInput) =>
    sendPasswordResetEmail(auth, data.email),
});

export const forgotPasswordInputSchema = z.object({
  email: z.string().min(1, 'Required').email('Invalid email'),
});

export type ForgotPasswordInput = z.infer<typeof forgotPasswordInputSchema>;
