import { DefaultOptions } from '@tanstack/react-query';
import axios from 'axios';
import { getAuth } from 'firebase/auth';

import { env } from '@/config/env';
import { useNotifications } from '@/ui/notifications';

axios.defaults.timeout = 0;
export const client = axios.create({
  baseURL: env.API_URL,
});
client.interceptors.request.use(
  async (config) => {
    const auth = getAuth();
    const token = await auth.currentUser?.getIdToken(true);
    if (token) {
      config.headers['Content-Type'] = 'application/json';
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

client.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    const message = error.response?.data?.message || error.message;
    useNotifications.getState().addNotification({
      type: 'error',
      title: 'Error',
      message,
    });

    if (error.response?.status === 401) {
      const searchParams = new URLSearchParams();
      const redirectTo = searchParams.get('redirectTo');
      window.location.href = `/auth/login?redirectTo=${redirectTo}`;
    }

    return Promise.reject(error);
  },
);

export const queryConfig = {
  queries: {
    // throwOnError: true,
    refetchOnWindowFocus: false,
    retry: false,
    staleTime: 1000 * 60,
  },
} satisfies DefaultOptions;
