import {
  IconCurrencyDollar,
  IconFile,
  IconHelp,
  IconHome,
  IconInfoCircle,
  IconLayoutSidebar,
  IconSettings,
  IconUsers,
} from '@tabler/icons-react';
import { useEffect, useState } from 'react';
import { NavLink, useNavigation } from 'react-router-dom';

import { useAccounts } from '@/api';
import logo from '@/assets/logo.png';
import { Alert, AlertTitle, AlertDescription } from '@/ui/alert';
import { Button } from '@/ui/button';
import { Card, CardContent } from '@/ui/card';
import { Drawer, DrawerContent, DrawerTrigger } from '@/ui/drawer';
import { cn } from '@/utils/cn';

type SideNavigationItem = {
  name: string;
  to: string;
  icon: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
};

const Logo = () => {
  return <img className="mr-5" src={logo} alt="Workflow" />;
};

const Progress = () => {
  const { state, location } = useNavigation();

  const [progress, setProgress] = useState(0);

  useEffect(() => {
    setProgress(0);
  }, [location?.pathname]);

  useEffect(() => {
    if (state === 'loading') {
      const timer = setInterval(() => {
        setProgress((oldProgress) => {
          if (oldProgress === 100) {
            clearInterval(timer);
            return 100;
          }
          const newProgress = oldProgress + 10;
          return newProgress > 100 ? 100 : newProgress;
        });
      }, 300);

      return () => {
        clearInterval(timer);
      };
    }
  }, [state]);

  if (state !== 'loading') {
    return null;
  }

  return (
    <div
      className="fixed left-0 top-0 h-1 bg-primary transition-all duration-200 ease-in-out"
      style={{ width: `${progress}%` }}
    ></div>
  );
};

export function DashboardLayout({ children }: { children: React.ReactNode }) {
  const navigation = [
    { name: 'Dashboard', to: '.', icon: IconHome },
    { name: 'Posts', to: './posts', icon: IconFile },
    { name: 'Accounts', to: './accounts', icon: IconUsers },
    { name: 'Payouts', to: './payouts', icon: IconCurrencyDollar },
    { name: 'Help', to: 'https://help.glamup.ai/', icon: IconHelp },
    { name: 'Settings', to: './settings', icon: IconSettings },
  ].filter(Boolean) as SideNavigationItem[];
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { data: accounts } = useAccounts();
  return (
    <div className="flex min-h-screen w-full flex-col bg-muted/40">
      <aside className="fixed inset-y-0 left-0 z-10 hidden w-60 flex-col border-r bg-pink-500 sm:flex">
        <nav className="flex flex-col items-center gap-4 px-2 py-4">
          <div className="flex h-16 shrink-0 items-center px-4">
            <Logo />
          </div>
          {navigation.map((item) => (
            <NavLink
              key={item.name}
              to={item.to}
              target={item.to.startsWith('http') ? '_blank' : undefined}
              end={item.name !== 'Submit'}
              className={({ isActive }) =>
                cn(
                  'text-white hover:bg-pink-200 hover:text-primary',
                  'group flex flex-1 w-full items-center rounded-md p-2 text-base font-medium',
                  isActive && 'bg-pink-300 text-primary',
                )
              }
            >
              <item.icon
                className={cn(
                  'mr-4 size-6 shrink-0',
                  ({ isActive }: { isActive: boolean }) =>
                    isActive ? 'text-primary' : 'text-white',
                )}
                aria-hidden="true"
              />
              {item.name}
            </NavLink>
          ))}
        </nav>
      </aside>
      <div className="flex flex-col sm:gap-4 sm:py-4 sm:pl-60">
        <header className="sticky top-0 z-30 flex h-14 items-center justify-between gap-4 border-b bg-background px-4 sm:static sm:h-auto sm:justify-end sm:border-0 sm:bg-transparent sm:px-6">
          <Progress />
          <Drawer open={isDrawerOpen} onOpenChange={setIsDrawerOpen}>
            <DrawerTrigger asChild>
              <Button size="icon" variant="outline" className="sm:hidden">
                <IconLayoutSidebar className="size-5" />
                <span className="sr-only">Toggle Menu</span>
              </Button>
            </DrawerTrigger>
            <DrawerContent
              side="left"
              className="bg-primary pt-10 text-white sm:max-w-60"
            >
              <nav className="grid gap-6 text-lg font-medium">
                <div className="flex h-16 shrink-0 items-center px-4">
                  <Logo />
                </div>
                {navigation.map((item) => (
                  <NavLink
                    key={item.name}
                    to={item.to}
                    target={item.to.startsWith('http') ? '_blank' : undefined}
                    onClick={() => setIsDrawerOpen(false)}
                    end
                    className={({ isActive }) =>
                      cn(
                        'text-white hover:bg-pink-200 hover:text-primary',
                        'group flex flex-1 w-full items-center rounded-md p-2 text-base font-medium',
                        isActive && 'bg-pink-300 text-primary',
                      )
                    }
                  >
                    <item.icon
                      className={cn(
                        'mr-4 size-6 shrink-0',
                        ({ isActive }: { isActive: boolean }) =>
                          isActive ? 'text-primary' : 'text-white',
                      )}
                      aria-hidden="true"
                    />
                    {item.name}
                  </NavLink>
                ))}
              </nav>
            </DrawerContent>
          </Drawer>
        </header>
        <main className="grid flex-1 items-start gap-4 p-4 sm:px-6 sm:py-0 md:gap-8">
          {accounts?.length === 0 && (
            <Card className="items-center justify-center pt-6">
              <CardContent>
                <Alert variant="destructive">
                  <IconInfoCircle color="red" className="size-5" />
                  <AlertTitle>Important</AlertTitle>
                  <AlertDescription>
                    You have not submitted your accounts yet. Please submit your
                    accounts to be able to submit posts.
                  </AlertDescription>
                </Alert>
              </CardContent>
            </Card>
          )}
          {children}
        </main>
      </div>
    </div>
  );
}
