import type { AxiosError } from 'axios';
import { getAuth, updateEmail, User } from 'firebase/auth';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { createMutation } from 'react-query-kit';

import { db } from '@/firebase-config';

import { Influencer } from '../types';

type Variables = Partial<Influencer>;
type Response = void;

export const useUpdateInfluencer = createMutation<
  Response,
  Variables,
  AxiosError
>({
  mutationFn: async (variables) => {
    const auth = getAuth();
    const uid = auth.currentUser?.uid as string;
    const docRef = doc(db, 'influencers', uid);
    const influencer = (await getDoc(docRef)).data();
    if (influencer?.email !== variables.email && variables.email) {
      await updateEmail(auth.currentUser as User, variables.email as string);
    }
    await updateDoc(docRef, variables);
    return;
  },
});
