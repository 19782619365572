import { initializeApp } from 'firebase/app';
import { connectAuthEmulator, initializeAuth } from 'firebase/auth';
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore';
import { connectStorageEmulator, getStorage } from 'firebase/storage';

import { env } from './config/env';

const APP_ENV = env.APP_ENV;
const firebaseConfig = {
  apiKey: 'AIzaSyBKC4b2OlxzhxiJXnb1BK35RdQ7rXZrwJ0',
  authDomain: 'glam-up-ai.firebaseapp.com',
  projectId: 'glam-up-ai',
  storageBucket: 'glam-up-ai.appspot.com',
  messagingSenderId: '837835306630',
  appId: '1:837835306630:web:2eb8754620443538828307',
  measurementId: 'G-HJ1GBVGS23',
};

const stagingFirebaseConfig = {
  apiKey: 'AIzaSyAFpBs14ejpcVaYAiQVr5vuAGHAiCSALxI',
  authDomain: 'glam-up-staging.firebaseapp.com',
  projectId: 'glam-up-staging',
  storageBucket: 'glam-up-staging.appspot.com',
  messagingSenderId: '621247981077',
  appId: '1:621247981077:web:3aaac034fd25192d70501c',
  measurementId: 'G-H7DFGRWDBT',
};
const app = initializeApp(
  APP_ENV === 'staging' ? stagingFirebaseConfig : firebaseConfig,
  {
    experimentalForceLongPolling: true,
  } as any,
);
export const auth = initializeAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);

if (APP_ENV === 'development') {
  connectAuthEmulator(auth, `http://127.0.0.1:9099`);
  connectStorageEmulator(storage, `127.0.0.1`, 9199);
  connectFirestoreEmulator(db, `127.0.0.1`, 8080);
}
