import type { AxiosError } from 'axios';
import { createMutation } from 'react-query-kit';
import { z } from 'zod';

import { client } from '../common';
import { InfluencerPost } from '../types';

type Variables = {
  contentUrl: string;
};
type Response = InfluencerPost;

export const useSubmitPost = createMutation<Response, Variables, AxiosError>({
  mutationFn: async (variables) =>
    client({
      url: '/influencers/posts',
      method: 'POST',
      data: variables,
    }).then((response) => response.data),
});

export const submitPostInputSchema = z.object({
  contentUrl: z.string().url(),
});
