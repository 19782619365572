import type { AxiosError } from 'axios';
import { collection, doc, getDoc } from 'firebase/firestore';
import { createQuery } from 'react-query-kit';

import { db } from '@/firebase-config';

import { InfluencerPostAnalysis } from '../types';

type Response = InfluencerPostAnalysis;
type Variables = { id: string };

export const usePostAnalysis = createQuery<Response, Variables, AxiosError>({
  queryKey: ['post-analysis'],
  fetcher: async ({ id }) => {
    try {
      const collectionRef = collection(db, 'influencerPostAnalyses');
      const docRef = doc(collectionRef, id);
      const docSnap = await getDoc(docRef);
      return docSnap.data() as InfluencerPostAnalysis;
    } catch (error) {
      console.log(error);
      throw new Error('Post analysis not found');
    }
  },
});
