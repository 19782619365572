import { useMemo } from 'react';
import {
  Navigate,
  Outlet,
  RouterProvider,
  createBrowserRouter,
} from 'react-router-dom';

import { ProtectedRoute } from '@/api/common/auth';

import { AppRoot } from './routes/app/root';
export const createAppRouter = () =>
  createBrowserRouter([
    {
      path: '/',
      element: <Navigate to="/app" replace />,
    },
    {
      path: '/auth/register',
      lazy: async () => {
        const { RegisterRoute } = await import('./routes/auth/register');
        return { Component: RegisterRoute };
      },
    },
    {
      path: '/auth/login',
      lazy: async () => {
        const { LoginRoute } = await import('./routes/auth/login');
        return { Component: LoginRoute };
      },
    },
    {
      path: '/auth/account',
      lazy: async () => {
        const { AddAccountRoute } = await import('./routes/auth/account');
        return { Component: AddAccountRoute };
      },
    },
    {
      path: '/auth/forgot-password',
      lazy: async () => {
        const { ForgotPassRoute } = await import(
          './routes/auth/forgot-password'
        );
        return { Component: ForgotPassRoute };
      },
    },
    {
      path: '/auth/sign-as',
      lazy: async () => {
        const { SignAsRoute } = await import('./routes/auth/sign-as');
        return { Component: SignAsRoute };
      },
    },
    {
      path: '/auth/success',
      lazy: async () => {
        const { SuccessRoute } = await import('./routes/auth/success');
        return { Component: SuccessRoute };
      },
    },
    {
      path: '/auth/pending',
      lazy: async () => {
        const { PendingRoute } = await import('./routes/auth/pending');
        return { Component: PendingRoute };
      },
    },
    {
      path: '/app',
      element: (
        <ProtectedRoute requireOnboarding={true}>
          <AppRoot />
        </ProtectedRoute>
      ),
      children: [
        {
          path: '',
          lazy: async () => {
            const { DashboardRoute } = await import('./routes/app/dashboard');
            return { Component: DashboardRoute };
          },
        },
        {
          path: 'posts',
          children: [
            {
              path: '',
              lazy: async () => {
                const { PostsRoute } = await import('./routes/app/posts/posts');
                return { Component: PostsRoute };
              },
            },
            {
              path: 'submit',
              lazy: async () => {
                const { SubmitPostsRoute } = await import(
                  './routes/app/posts/submit'
                );
                return { Component: SubmitPostsRoute };
              },
            },
            {
              path: ':id',
              lazy: async () => {
                const { PostRoute } = await import('./routes/app/posts/post');
                return { Component: PostRoute };
              },
            },
          ],
        },
        {
          path: 'payouts',
          children: [
            {
              path: '',
              lazy: async () => {
                const { PayoutsRoute } = await import(
                  './routes/app/payouts/payouts'
                );
                return { Component: PayoutsRoute };
              },
            },
            {
              path: ':id',
              lazy: async () => {
                const { PostRoute } = await import('./routes/app/posts/post');
                return { Component: PostRoute };
              },
            },
          ],
        },
        {
          path: 'accounts',
          children: [
            {
              path: '',
              lazy: async () => {
                const { AccountsRoute } = await import(
                  './routes/app/accounts/accounts'
                );
                return { Component: AccountsRoute };
              },
            },
            {
              path: 'submit',
              lazy: async () => {
                const { SubmitAccountRoute } = await import(
                  './routes/app/accounts/submit'
                );
                return { Component: SubmitAccountRoute };
              },
            },
          ],
        },
        {
          path: 'settings',
          lazy: async () => {
            const { SettingsRoute } = await import('./routes/app/settings');
            return { Component: SettingsRoute };
          },
        },
      ],
    },
    {
      path: 'onboarding',
      element: (
        <ProtectedRoute requireOnboarding={false}>
          <Outlet />
        </ProtectedRoute>
      ),
      children: [
        {
          path: 'welcome',
          lazy: async () => {
            const { OnboardingWelcomeRoute } = await import(
              './routes/onboarding/welcome'
            );
            return { Component: OnboardingWelcomeRoute };
          },
        },
        {
          path: 'about',
          lazy: async () => {
            const { OnboardingAboutRoute } = await import(
              './routes/onboarding/about'
            );
            return { Component: OnboardingAboutRoute };
          },
        },
        {
          path: 'payouts',
          lazy: async () => {
            const { OnboardingPayoutsRoute } = await import(
              './routes/onboarding/payouts'
            );
            return { Component: OnboardingPayoutsRoute };
          },
        },
        {
          path: 'next-steps',
          lazy: async () => {
            const { OnboardingNextStepsRoute } = await import(
              './routes/onboarding/next-steps'
            );
            return { Component: OnboardingNextStepsRoute };
          },
        },
        {
          path: 'contract',
          lazy: async () => {
            const { OnboardingContractRoute } = await import(
              './routes/onboarding/contract'
            );
            return { Component: OnboardingContractRoute };
          },
        },
        {
          path: 'app-setup',
          lazy: async () => {
            const { OnboardingAppSetupRoute } = await import(
              './routes/onboarding/app-setup'
            );
            return { Component: OnboardingAppSetupRoute };
          },
        },
        {
          path: 'guidelines',
          lazy: async () => {
            const { OnboardingGuidelinesRoute } = await import(
              './routes/onboarding/guidelines'
            );
            return { Component: OnboardingGuidelinesRoute };
          },
        },
        {
          path: 'dashboard',
          lazy: async () => {
            const { OnboardingDashboardRoute } = await import(
              './routes/onboarding/dashboard'
            );
            return { Component: OnboardingDashboardRoute };
          },
        },
      ],
    },
    {
      path: '*',
      lazy: async () => {
        const { NotFoundRoute } = await import('./routes/not-found');
        return { Component: NotFoundRoute };
      },
    },
  ]);

export const AppRouter = () => {
  const router = useMemo(() => createAppRouter(), []);

  return <RouterProvider router={router} />;
};
