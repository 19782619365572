import type { AxiosError } from 'axios';
import { getAuth } from 'firebase/auth';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { createQuery } from 'react-query-kit';

import { db } from '@/firebase-config';

import { InfluencerAccount } from '../types';

type Response = InfluencerAccount[];
type Variables = void;

export const useAccounts = createQuery<Response, Variables, AxiosError>({
  queryKey: ['accounts'],
  fetcher: async () => {
    try {
      const auth = getAuth();
      const uid = auth.currentUser?.uid;
      const collectionRef = collection(db, 'influencerAccounts');
      const q = query(collectionRef, where('uid', '==', uid));
      const querySnapshot = await getDocs(q);
      return querySnapshot.docs.map((doc) => doc.data()) as InfluencerAccount[];
    } catch (error) {
      throw new Error('Accounts not found');
    }
  },
});
